<template>
  <div>
    <el-dialog
        title="新增班次"
        :visible.sync="createDialog"
        width="860px">
      <el-form label-position="top" :model="classes" :rules="rules" ref="formRef" label-width="110px">
        <el-row>
          <el-col :span="8">
            <el-form-item label-width="100px" label="名称" prop="name">
              <el-input v-model="classes.name" style="width: 210px" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label-width="100px" label="管理老师">
              <el-select v-model="classes.parentId" clearable placeholder="请选择">
                <el-option
                    v-for="item in tableList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label-width="100px" prop="workDay">
              <template #label>
                班次工时
                <el-tooltip :content="'合计工作时长'+convertMinutesToDHM(totalTime)+'，计为'+classes.workDay+'天出勤'" placement="top">
                  <i class="el-icon-info" style="margin-left: 2px;"></i>
                </el-tooltip>
              </template>
              <el-input-number v-model="classes.workDay" :precision="2" :step="0.1" :max="10" label="请输入"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <div style="display: flex;">
          <div style="width: 390px;margin-right: 20px">
            <el-table
                :data="classes.classesDetails"
                border
                style="border-radius: 10px">
              <el-table-column width="140" label="上班时间">
                <template #default="scope">
                  <!-- 确保 prop 路径正确 -->
                  <el-form-item :prop="'classesDetails[' + scope.$index + '].toTime'"
                                :rules="{required: true, message: '请选择时间', trigger: 'blur'}" style="margin: 0">
                    <el-time-picker
                        format="HH:mm"
                        value-format="HH:mm"
                        size="mini" style="width: 120px"
                        v-model="scope.row.toTime" placeholder="请选择">
                    </el-time-picker>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column width="190" prop="name" label="打卡时段">
                <template #default="scope">
                  <div>
                    上班前
                    <el-input-number style="width: 100px" :max="240" size="mini" v-model="scope.row.startToTime"></el-input-number>
                    分
                  </div>
<!--                  <div style="margin-top: 8px">-->
<!--                    上班后-->
<!--                    <el-input-number style="width: 100px" max="240" size="mini" v-model="scope.row.endToTime"></el-input-number>-->
<!--                    分-->
<!--                  </div>-->
                </template>
              </el-table-column>
              <el-table-column align="center" width="58" label="打卡">
                <template #default="scope">
                  <el-checkbox :disabled="scope.$index == 0" :true-label="1" :false-label="0" v-model="scope.row.toClock"></el-checkbox>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div style="width: 384px;">
            <el-table
                :data="classes.classesDetails"
                border
                style="border-radius: 10px">
              <el-table-column width="140" prop="date" label="下班时间">
                <template #default="scope">
                  <!-- 确保 prop 路径正确 -->
                  <el-form-item :prop="'classesDetails[' + scope.$index + '].offTime'"
                                :rules="{required: true, message: '请选择时间', trigger: 'blur'}" style="margin: 0">
                    <el-time-picker format="HH:mm" value-format="HH:mm"
                                    size="mini" style="width: 120px"
                                    v-model="scope.row.offTime" placeholder="请选择">
                    </el-time-picker>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column width="190" prop="classesDetails.toTime" label="打卡时段">
                <template #default="scope">
<!--                  <div>-->
<!--                    下班前-->
<!--                    <el-input-number style="width: 100px" max="240" size="mini" v-model="scope.row.startOffTime"></el-input-number>-->
<!--                    分-->
<!--                  </div>-->
                  <div style="margin-top: 8px">
                    下班后
                    <el-input-number style="width: 100px" :max="240" size="mini" v-model="scope.row.endOffTime"></el-input-number>
                    分
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" width="52" label="打卡">
                <template #default="scope">
                  <el-checkbox :true-label="1" :false-label="0" v-model="scope.row.offClock"></el-checkbox>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div>
            <div style="height: 50px"></div>
            <div style="height: 60px;margin-bottom: 4px" v-for="(item,index) in classes.classesDetails" :key="index">
              <span v-if="index != 0" @click="deleteClasses(index)" class="el-icon-delete delete-classes"></span>
            </div>
          </div>
        </div>
        <div v-if="classes.classesDetails.length<3" class="add-classes">
          <el-button @click="addClockingIn" type="danger">添 加</el-button>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createDialog = false">取 消</el-button>
        <el-button type="danger" @click="createdClasses">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { createdClasses } from "../../../../api/attendance/classes";

export default {
  name: "CreatedClasses",
  data() {
    return {
      createDialog: false,
      tableList: [],
      classes: {
        name: "",
        directorId: null,
        workDay: 1,
        workTime: 0,
        classesDetails: [{
            endOffTime: null,
            endToTime: null,
            offClock: 0,
            offTime: null,
            startOffTime: null,
            startToTime: null,
            toClock: 1,
            toTime: null
          }],
      },
      rules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" }
        ],
        workDay: [
          { required: true, message: "请输入", trigger: "blur" }
        ],
      }
    };
  },
  computed: {
    totalTime () {
      let total = 0;
      for (const item of this.classes.classesDetails) {
        if (item.toTime && item.offTime){
          let top = this.timeToMinute(item.toTime);
          let off = this.timeToMinute(item.offTime);
          //正常日期设置规则。
          if (off > top){
            total = total + (off-top);
          }else {
            // 次日日期设置规则。
            total = total + 1440-top+off;
          }
        }
      }
      return total;
    },
  },
  methods: {
    /**
     * 分钟 转 天:小时:分钟
     */
     convertMinutesToDHM(minutes) {
       let string = "";
      // 计算天数
      const days = Math.floor(minutes / (24 * 60));
      // 计算剩余的分钟数用于后续计算小时
      minutes %= (24 * 60);
      // 计算小时数
      const hours = Math.floor(minutes / 60);
      // 计算最终剩余的分钟数
      minutes %= 60;
      string = days>0 ? days+"天":"";
      string = string + (hours>0 ? hours+"小时":"");
      string = string + (minutes>0 ? minutes+"分钟":"");
      return string;
    },
    /**
     * 00:00 转多少分钟
     */
    timeToMinute(time){
      const [hour, minute] = time.split(':').map(Number);
      return hour*60+minute;
    },
    deleteClasses(index){
      this.classes.classesDetails.splice(index, 1);
    },
    addClockingIn(){
      let item = {
            endOffTime: null,
            endToTime: null,
            offClock: 0,
            offTime: "",
            startOffTime: null,
            startToTime: null,
            toClock: 0,
            toTime: ""
        }
      this.classes.classesDetails.push(item);
    },
    openDialog() {
      this.createDialog = true;
    },
    createdClasses() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.classes.workTime = this.totalTime;
          createdClasses(this.classes).then(val => {
            if (val.status == 200){
              this.$emit('refresh');
              this.createDialog = false;
              this.$message({message: '创建成功', type: 'success'});
              Object.keys(this.classes).forEach((key) => (this.classes[key] = null));
              this.classes.classesDetails = [{
                endOffTime: 0,
                endToTime: 0,
                offClock: 0,
                offTime: null,
                startOffTime: 0,
                startToTime: 0,
                toClock: 0,
                toTime: null
              }];
            }else {
              this.$message.error('创建失败！');
            }
          })
        }
      });
    }
  }
};
</script>

<style scoped>
.add-classes{
  text-align: center;
  margin-top: 30px;
}
.delete-classes{
  position: relative;right: -16px;top: 50%;transform: translateY(-50%);color: red;
  font-weight: bold;
}
</style>