<template>
  <div>
    <div style="margin-bottom: 20px">
      <el-button type="danger" @click="createdItem">新 增</el-button>
      <el-input v-model="tableQuery.name" style="width: 200px;margin: 0 10px" placeholder="请输入名称"></el-input>
      <el-button :loading="tableLoading" type="danger" @click="selectClassesPage">搜 索</el-button>
    </div>
    <el-table
        :data="tableData" class="table" height="626px" border v-loading="tableLoading">
      <el-table-column prop="id" label="ID" width="160"/>
      <el-table-column prop="name" label="名称" width="240"/>
      <el-table-column label="考勤时间" min-width="300" align="center">
        <template #default="scope">
          <div style="display: flex;">
            <div v-for="(item, index) in scope.row.classesDetails" :key="index">
              <el-popover placement="top" width="260" trigger="hover">
                <div class="to">
                  <div style="text-align: center;font-weight: bold">上班</div>
                  <div style="margin: 4px 0 2px 0;display: flex;justify-content: space-between">
                    <div style="width: 50%">上班时间：{{item.toTime}}
                      <el-checkbox style="margin-left: 4px" size="mini" :true-label="1" :false-label="0" v-model="item.toClock"></el-checkbox>
                    </div>
                  </div>
                  <div>打卡时段：上班前{{item.startToTime}}分钟 <span class="el-icon-minus"></span> 上班后{{item.endToTime}}分钟</div>
                </div>
                <div class="off">
                  <div style="text-align: center;font-weight: bold">下班</div>
                  <div style="margin: 4px 0 2px 0;display: flex;justify-content: space-between">
                    <div style="width: 50%">下班时间：{{item.offTime}}
                      <el-checkbox style="margin-left: 4px" size="mini" :true-label="1" :false-label="0" v-model="item.offClock"></el-checkbox>
                    </div>
                  </div>
                  <div>打卡时段：下班前{{item.startOffTime}}分钟 <span class="el-icon-minus"></span>  下班后{{item.endOffTime}}分钟</div>
                </div>
                <template #reference>
                  <el-tag type="danger" style="margin-right: 20px;display: flex;align-items: center">
                    <div class="is-clock-in" style="margin-right: 4px" v-if="item.toClock == 1"></div>
                    {{item.toTime}} ~
                    <div class="is-clock-in"  style="margin:0 4px 0 6px" v-if="item.offClock == 1"></div>
                    {{item.offTime}}
                  </el-tag>
                </template>
              </el-popover>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="directorName" label="管理人员" width="200"/>
      <el-table-column prop="addTime" label="创建时间" width="220"/>
      <el-table-column label="操作" width="110" align="center">
        <template #default="scope">
          <div style="margin-right: 4px;">
            <el-button type="text" size="small" style="margin-right: 10px" @click.prevent="updateItem(scope.row)">编辑</el-button>
            <el-popconfirm title="确定要删除该班次吗？" @confirm="deleteItem(scope.row.id)">
              <el-button slot="reference" type="text" size="small" style="color: red;">删除</el-button>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        style="text-align: right;margin: 20px 10px"
        :page-sizes="[100, 200, 300, 400]"
        :page-size="10"
        @current-change="changePage"
        layout="total, prev, pager, next, jumper"
        :total="tableTotal">
    </el-pagination>
    <created-classes @refresh="selectClassesPage" ref="created"/>
    <update-classes @refresh="selectClassesPage" ref="update"/>
  </div>
</template>

<script>
import {selectClassesPage,deleteClasses} from '/src/api/attendance/classes'
import CreatedClasses from "./CreatedClasses";
import UpdateClasses from "./UpdateClasses";
export default {
  name: "Classes",
  components:{
    CreatedClasses,UpdateClasses
  },
  data() {
    return {
      tableQuery:{
        name:"",
        pageIndex:0,
        pageSize:10,
      },
      tableTotal:0,
      tableData:[],
      roleGroup:[],
      tableLoading:false,
    }
  },
  methods:{
    createdItem(){
      this.$refs.created.openDialog();
    },
    updateItem(item){
      this.$refs.update.openDialog(item);
    },
    deleteItem(id){
      deleteClasses(id).then(val => {
        if (val.status){
          this.selectClassesPage();
          this.$message({message: '删除成功！', type: 'success'});
        }else {
          this.$message.error('删除失败！');
        }
      })
    },
    changePage(val){
      this.tableQuery.pageIndex = val;
      this.selectClassesPage();
    },
    selectClassesPage(){
      this.tableLoading = true;
      selectClassesPage(this.tableQuery).then(val => {
        this.tableLoading = false;
        if (val.status){
          this.tableData = val.data.list;
          this.tableTotal = val.data.total;
        }
      })
    },
  },
  created() {
    this.selectClassesPage();
  }
}
</script>

<style scoped>
.to{
  background-color: #F5F7FA;
  border-radius: 10px;
  padding: 10px;
  font-size: 12px;
}
.off{
  background-color: #F5F7FA;
  border-radius: 10px;
  margin: 10px 0;
  padding: 10px;
  font-size: 12px;
}
.table{
  width: 100%;
  border-radius: 10px;
  padding: 6px 0px 10px 6px;
}
.is-clock-in{
  height: 5px;width: 5px;background-color: #67C23A;border-radius: 50px;
}
</style>